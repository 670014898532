import { ReactComponent as AccountBalanceWalletFillSymbol } from '@material-symbols/svg-400/outlined/account_balance_wallet-fill.svg';
import { ReactComponent as AccountBalanceWalletSymbol } from '@material-symbols/svg-400/outlined/account_balance_wallet.svg';
import { ReactComponent as CalendarTodayFillSymbol } from '@material-symbols/svg-400/outlined/calendar_today-fill.svg';
import { ReactComponent as CalendarTodaySymbol } from '@material-symbols/svg-400/outlined/calendar_today.svg';
import { ReactComponent as DashboardFillSymbol } from '@material-symbols/svg-400/outlined/dashboard-fill.svg';
import { ReactComponent as DashboardSymbol } from '@material-symbols/svg-400/outlined/dashboard.svg';
import { ReactComponent as GroupFillSymbol } from '@material-symbols/svg-400/outlined/group-fill.svg';
import { ReactComponent as GroupSymbol } from '@material-symbols/svg-400/outlined/group.svg';
import { ReactComponent as HistorySymbol } from '@material-symbols/svg-400/outlined/history.svg';
import { ReactComponent as LogoutSymbol } from '@material-symbols/svg-400/outlined/logout.svg';
import { ReactComponent as MailFillSymbol } from '@material-symbols/svg-400/outlined/mail-fill.svg';
import { ReactComponent as MailSymbol } from '@material-symbols/svg-400/outlined/mail.svg';
import { ReactComponent as ManageAccountsFillSymbol } from '@material-symbols/svg-400/outlined/manage_accounts-fill.svg';
import { ReactComponent as ManageAccountsSymbol } from '@material-symbols/svg-400/outlined/manage_accounts.svg';
import { ReactComponent as PatientListFillSymbol } from '@material-symbols/svg-400/outlined/patient_list-fill.svg';
import { ReactComponent as PatientListSymbol } from '@material-symbols/svg-400/outlined/patient_list.svg';
import { ReactComponent as PaymentsFillSymbol } from '@material-symbols/svg-400/outlined/payments-fill.svg';
import { ReactComponent as PaymentsSymbol } from '@material-symbols/svg-400/outlined/payments.svg';
import { ReactComponent as PersonFillSymbol } from '@material-symbols/svg-400/outlined/person-fill.svg';
import { ReactComponent as PersonSymbol } from '@material-symbols/svg-400/outlined/person.svg';
import { ReactComponent as QuestionMarkSymbol } from '@material-symbols/svg-400/outlined/question_mark.svg';
import { ReactComponent as SettingsFillSymbol } from '@material-symbols/svg-400/outlined/settings-fill.svg';
import { ReactComponent as SettingsSymbol } from '@material-symbols/svg-400/outlined/settings.svg';
import { ReactComponent as SwapHorizSymbol } from '@material-symbols/svg-400/outlined/swap_horiz.svg';
import { ReactComponent as TeamDashboardFillSymbol } from '@material-symbols/svg-400/outlined/team_dashboard-fill.svg';
import { ReactComponent as TeamDashboardSymbol } from '@material-symbols/svg-400/outlined/team_dashboard.svg';
import { ReactComponent as ViewDayFillSymbol } from '@material-symbols/svg-400/outlined/view_day-fill.svg';
import { ReactComponent as ViewDaySymbol } from '@material-symbols/svg-400/outlined/view_day.svg';
import { Box, useTheme, ButtonBase, ButtonBaseProps, Typography, Badge } from '@mui/material';
import { SvgIcon, useLoadedAuthUserContext } from '@stationwise/component-module';
import { makeTestIdentifier } from '@stationwise/share-utils';

export type MenuIconTypes =
  | 'dashboard'
  | 'roster'
  | 'employees'
  | 'staffing-lists'
  | 'department-settings'
  | 'payroll'
  | 'support'
  | 'logout'
  | 'my-dashboard'
  | 'calendar'
  | 'my-roster'
  | 'my-payroll'
  | 'my-staffing-lists'
  | 'preferences'
  | 'change-department'
  | 'inbox'
  | 'change-log'
  | 'you';

export const getIconData = (type: MenuIconTypes) => {
  switch (type) {
    case 'roster':
      return {
        Component: ViewDaySymbol,
        ActiveComponent: ViewDayFillSymbol,
        title: 'Roster',
      };
    case 'dashboard':
      return {
        Component: TeamDashboardSymbol,
        ActiveComponent: TeamDashboardFillSymbol,
        title: 'Dashboard',
      };
    case 'employees':
      return {
        Component: GroupSymbol,
        ActiveComponent: GroupFillSymbol,
        title: 'Employees',
      };
    case 'staffing-lists':
      return {
        Component: PatientListSymbol,
        ActiveComponent: PatientListFillSymbol,
        title: 'Staffing Lists',
      };
    case 'payroll':
      return {
        Component: AccountBalanceWalletSymbol,
        ActiveComponent: AccountBalanceWalletFillSymbol,
        title: 'Payroll',
      };
    case 'department-settings':
      return {
        Component: SettingsSymbol,
        ActiveComponent: SettingsFillSymbol,
        title: 'Department',
      };
    case 'support':
      return {
        Component: QuestionMarkSymbol,
        ActiveComponent: QuestionMarkSymbol,
        title: 'Support',
      };
    case 'change-department':
      return {
        Component: SwapHorizSymbol,
        ActiveComponent: SwapHorizSymbol,
        title: 'Change Department',
      };
    case 'logout':
      return {
        Component: LogoutSymbol,
        ActiveComponent: LogoutSymbol,
        title: 'Logout',
      };
    case 'calendar':
      return {
        Component: CalendarTodaySymbol,
        ActiveComponent: CalendarTodayFillSymbol,
        title: 'My Calendar',
      };
    case 'my-roster':
      return {
        Component: ViewDaySymbol,
        ActiveComponent: ViewDayFillSymbol,
        title: 'Roster',
      };
    case 'my-payroll':
      return {
        Component: PaymentsSymbol,
        ActiveComponent: PaymentsFillSymbol,
        title: 'My Payroll',
      };
    case 'my-staffing-lists':
      return {
        Component: PatientListSymbol,
        ActiveComponent: PatientListFillSymbol,
        title: 'My Staffing Lists',
      };
    case 'preferences':
      return {
        Component: ManageAccountsSymbol,
        ActiveComponent: ManageAccountsFillSymbol,
        title: 'Preferences',
      };
    case 'my-dashboard':
      return {
        Component: DashboardSymbol,
        ActiveComponent: DashboardFillSymbol,
        title: 'My Dashboard',
      };
    case 'inbox':
      return {
        Component: MailSymbol,
        ActiveComponent: MailFillSymbol,
        title: 'Messages',
      };
    case 'change-log':
      return {
        Component: HistorySymbol,
        ActiveComponent: HistorySymbol,
        title: 'Change Log',
      };
    case 'you':
      return {
        Component: PersonSymbol,
        ActiveComponent: PersonFillSymbol,
        title: 'You',
      };
  }
};

interface OurMenuIconProps {
  isActive?: boolean;
  type: MenuIconTypes;
}

type MenuIconProps<RootComponent extends React.ElementType = 'button'> = ButtonBaseProps<RootComponent, OurMenuIconProps>;

export const MenuIcon = <RootComponent extends React.ElementType>({
  isActive = false,
  type,
  inSideBar = false,
  ...props
}: MenuIconProps<RootComponent>) => {
  const theme = useTheme();
  const iconData = getIconData(type);
  const { state: authUserState } = useLoadedAuthUserContext();
  const notificationCount = authUserState.employee.unreadMessages.length;

  return (
    <ButtonBase
      data-cy={`menu-icon-${makeTestIdentifier(type)}`}
      {...props}
      sx={{
        background: isActive ? theme.palette.action.selected : 'none',
        color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '64px',
        width: '72px',
        '& .SWMenuIcon-default': { display: isActive ? 'none' : 'flex' },
        '& .SWMenuIcon-active': { display: isActive ? 'flex' : 'none' },
        '&:hover': {
          background: theme.palette.action.hover,
          color: theme.palette.text.primary,
          '& svg': {
            color: theme.palette.common.black,
          },
        },
        '& svg': {
          color: isActive ? theme.palette.common.black : theme.palette.text.secondary,
        },
      }}
    >
      <Box className="SWMenuIcon-default" component="span" display="flex" flexDirection="column" alignItems="center">
        {iconData.Component === MailSymbol && (
          <Badge badgeContent={notificationCount} color="primary">
            <SvgIcon component={iconData.Component} />
          </Badge>
        )}
        {iconData.Component !== MailSymbol && <SvgIcon component={iconData.Component} />}
        {inSideBar && (
          <Typography variant="caption" fontSize="10px" textAlign="center" letterSpacing="-0.03em">
            {iconData.title}
          </Typography>
        )}
      </Box>
      <Box className="SWMenuIcon-active" component="span" display="flex" flexDirection="column" alignItems="center">
        {iconData.Component === MailSymbol && (
          <Badge badgeContent={notificationCount} color="primary">
            <SvgIcon component={iconData.ActiveComponent} />
          </Badge>
        )}
        {iconData.Component !== MailSymbol && <SvgIcon component={iconData.ActiveComponent} />}
        {inSideBar && (
          <Typography variant="caption" fontSize="10px" textAlign="center" letterSpacing="-0.03em">
            {iconData.title}
          </Typography>
        )}
      </Box>
    </ButtonBase>
  );
};
