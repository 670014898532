import { Box, Tab, Tabs, Typography, TabProps } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getDepartmentFeatureFlagValue,
  useAuthUserCapabilities,
  useLoadedDepartmentInfoContext,
  theme,
} from '@stationwise/component-module';
import { ROUTES } from '../../core/Routes';

const TAB_PATHS = {
  allEmployees: ROUTES.EMPLOYEES.fullRoute,
  workGroups: ROUTES.EMPLOYEES.fullRoute + '/work-groups',
  payroll: ROUTES.PAYROLL.fullRoute,
  staffingLists: ROUTES.STAFFING_LISTS.fullRoute,
  goodStandingSummary: ROUTES.GOOD_STANDING_SUMMARY.fullRoute,
};

const TAB_LABELS = {
  allEmployees: 'All Employees',
  workGroups: 'Work Groups',
  payroll: 'Payroll and Time Cards',
  staffingLists: 'Staffing Lists',
  goodStandingSummary: 'Good Standing Summary',
};

interface EmployeesTabProps {
  children: ReactNode;
  defaultTab: 'allEmployees' | 'workGroups' | 'payroll' | 'staffingLists' | 'goodStandingSummary';
}

interface CustomTabProps extends TabProps {
  label: string;
  value: number;
}

export const EmployeesTab = ({ children, defaultTab }: EmployeesTabProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const capabilities = useAuthUserCapabilities();
  const canViewTeamSettings = capabilities.VIEW_EMPLOYEES_PAGE;
  const canViewStaffingList = capabilities.VIEW_STAFFING_LIST;
  const canViewPayroll = capabilities.VIEW_ADMIN_PAYROLL;

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const memberInGoodStandingEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'MEMBER_IN_GOOD_STANDING', false);

  const getInitialTab = () => {
    if (!canViewTeamSettings && canViewStaffingList) {
      return 3;
    }

    const tabMap = {
      [TAB_PATHS.allEmployees]: 0,
      [TAB_PATHS.payroll]: 1,
      [TAB_PATHS.workGroups]: 2,
      [TAB_PATHS.staffingLists]: 3,
      [TAB_PATHS.goodStandingSummary]: 4,
    };

    const defaultTabMap = {
      allEmployees: 0,
      payroll: 1,
      workGroups: 2,
      staffingLists: 3,
      goodStandingSummary: 4,
    };

    return tabMap[location.pathname] ?? defaultTabMap[defaultTab] ?? 3;
  };

  const [activeTab, setActiveTab] = useState(getInitialTab);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    switch (newValue) {
      case 0:
        navigate(TAB_PATHS.allEmployees);
        break;
      case 1:
        navigate(TAB_PATHS.payroll);
        break;
      case 2:
        navigate(TAB_PATHS.workGroups);
        break;
      case 3:
        navigate(TAB_PATHS.staffingLists);
        break;
      case 4:
        navigate(TAB_PATHS.goodStandingSummary);
        break;
    }
  };

  const CustomTab = React.forwardRef<HTMLDivElement, CustomTabProps>(function CustomTab(props, ref) {
    const { label, value, ...otherProps } = props;
    return (
      <Tab
        ref={ref}
        {...otherProps}
        sx={{
          textTransform: 'none',
          color: theme.palette.text.primary,
          typography: 'body2',
          alignItems: 'flex-start',
          textAlign: 'left',
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            color: theme.palette.text.primary,
          },
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
        label={label}
        value={value}
      />
    );
  });

  const visibleTabs = [
    ...(canViewTeamSettings
      ? [
          { label: TAB_LABELS.allEmployees, value: 0 },
          { label: TAB_LABELS.workGroups, value: 2, dataCy: 'work-groups-tab' },
        ]
      : []),
    ...(canViewPayroll ? [{ label: TAB_LABELS.payroll, value: 1, dataCy: 'admin-payroll-tab' }] : []),
    ...(canViewStaffingList ? [{ label: TAB_LABELS.staffingLists, value: 3, dataCy: 'staffing-lists-tab' }] : []),
    ...(memberInGoodStandingEnabled
      ? [{ label: TAB_LABELS.goodStandingSummary, value: 4, dataCy: 'good-standing-summary-tab' }]
      : []),
  ];

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', background: 'white', overflow: 'hidden' }}
    >
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Typography sx={(theme) => ({ color: theme.palette.text.primary, typography: 'h4' })}>Employees</Typography>
      </Box>
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <Box sx={{ borderRight: 1, borderColor: 'divider', width: '256px' }}>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Employees section tabs"
            sx={(theme) => ({
              '& .MuiTab-root': {
                alignItems: 'flex-start',
                textAlign: 'left',
                px: theme.spacing(2),
                py: theme.spacing(1.5),
                textTransform: 'none',
              },
            })}
          >
            {visibleTabs.map((tab) => (
              <CustomTab key={tab.value} label={tab.label} value={tab.value} data-cy={tab.dataCy} />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ flex: 1, height: '100%', overflow: 'auto' }}>{children}</Box>
      </Box>
    </Box>
  );
};
