import { Box, Tab, TabProps, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { theme, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import {
  CapabilityPermission,
  Certification,
  DetailCode,
  PayCodeComplete,
  RankComplete,
  TimeOffAccruals,
} from '@stationwise/share-types';
import { DepartmentSettingsTab } from './DepartmentSettingsTab';
import { ElementTypes } from './DepartmentSettingsUtil';
import { HiringEngineDepartmentSettingsTab } from './HiringEngineDepartmentSettingsTab';

interface CustomTabProps extends TabProps {
  label: string;
  value: number;
}

const CustomTab = React.forwardRef<HTMLDivElement, CustomTabProps>(function CustomTab(props, ref) {
  const { label, value, ...otherProps } = props;
  return (
    <Tab
      ref={ref}
      {...otherProps}
      sx={{
        textTransform: 'none',
        color: theme.palette.text.primary,
        typography: 'body2',
        alignItems: 'flex-start',
        textAlign: 'left',
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected,
          color: theme.palette.text.primary,
        },
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      }}
      label={label}
      value={value}
    />
  );
});

export const DepartmentSettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [selectedCertification, setSelectedCertification] = useState<Certification>();
  const [ranks, setRanks] = useState<RankComplete[]>([]);
  const [selectedRank, setSelectedRank] = useState<RankComplete>();
  const [detailCodes, setDetailCodes] = useState<DetailCode[]>([]);
  const [selectedDetailCode, setSelectedDetailCode] = useState<DetailCode>();
  const [payCodes, setPayCodes] = useState<PayCodeComplete[]>([]);
  const [selectedPayCode, setSelectedPayCode] = useState<PayCodeComplete>();
  const [capabilityPermissions, setCapabilityPermissions] = useState<CapabilityPermission[]>([]);
  const [selectedCapabilityPermission, setSelectedCapabilityPermission] = useState<CapabilityPermission>();
  const [timeOffAccruals, setTimeOffAccruals] = useState<TimeOffAccruals[]>([]);
  const [selectedTimeOffAccrual, setSelectedTimeOffAccrual] = useState<TimeOffAccruals>();
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedRank(undefined);
    setSelectedCertification(undefined);
    setSelectedDetailCode(undefined);
    setSelectedPayCode(undefined);
    setSelectedCapabilityPermission(undefined);
    setSelectedTimeOffAccrual(undefined);
    setSelectedTab(newValue);
  };

  const getCurrentTabLabel = () => {
    const tabLabels = [
      'Ranks',
      'Certifications',
      'Detail Codes',
      'Pay Codes',
      'Permissions',
      'Hiring Engine',
      'Accrual Banks',
      'Product Backlog',
    ];
    return tabLabels[selectedTab] || '';
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', background: 'white', overflow: 'hidden' }}
    >
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Box component="h1" sx={{ m: 0, typography: 'h4' }}>
          Department Settings
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <Box sx={{ borderRight: 1, borderColor: 'divider', width: '256px' }}>
          <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={handleTabChange}
            sx={(theme) => ({
              '& .MuiTab-root': {
                alignItems: 'flex-start',
                textAlign: 'left',
                px: theme.spacing(2),
                py: theme.spacing(1.5),
                textTransform: 'none',
              },
            })}
          >
            <CustomTab label="Ranks" value={0} data-cy={`rank-settings-tab`} />
            <CustomTab label="Certifications" value={1} data-cy={`certification-settings-tab`} />
            <CustomTab label="Detail Codes" value={2} data-cy={`detail-code-settings-tab`} />
            <CustomTab label="Pay Codes" value={3} data-cy={`pay-code-settings-tab`} />
            <CustomTab label="Permissions" value={4} data-cy={`permission-settings-tab`} />
            <CustomTab label="Hiring Engine" value={5} data-cy={`hiring-engine-settings-tab`} />
            <CustomTab label="Accrual Banks" value={6} data-cy={`accrual-bank-settings-tab`} />
            {departmentInfo.settings.productFeaturesListEmbedUrl && (
              <CustomTab label="Product Backlog" value={7} data-cy={`product-features-settings-tab`} />
            )}
          </Tabs>
        </Box>
        <Box sx={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Box sx={{ p: theme.spacing(2), borderBottom: `1px solid ${theme.palette.divider}` }}>
            <Typography sx={{ color: theme.palette.text.primary, typography: 'h5' }}>{getCurrentTabLabel()}</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
            {selectedTab === 0 && (
              <DepartmentSettingsTab
                elements={ranks}
                setElements={setRanks}
                selectedElement={selectedRank}
                setSelectedElement={setSelectedRank}
                createUpdateUrl={'organization/ranks/'}
                elementType={ElementTypes.RANK}
                exportUrl={'organization/ranks/export/'}
              />
            )}
            {selectedTab === 1 && (
              <DepartmentSettingsTab
                elements={certifications}
                setElements={setCertifications}
                selectedElement={selectedCertification}
                setSelectedElement={setSelectedCertification}
                createUpdateUrl={'certification/certifications/'}
                elementType={ElementTypes.CERTIFICATION}
                isFilterLowerCase={true}
                exportUrl={'certification/certifications/export/'}
              />
            )}
            {selectedTab === 2 && (
              <DepartmentSettingsTab
                elements={detailCodes}
                setElements={setDetailCodes}
                selectedElement={selectedDetailCode}
                setSelectedElement={setSelectedDetailCode}
                createUpdateUrl={'payroll/detail-code/'}
                elementType={ElementTypes.DETAIL_CODE}
                exportUrl={'payroll/detail-code/export/'}
              />
            )}
            {selectedTab === 3 && (
              <DepartmentSettingsTab
                elements={payCodes}
                setElements={setPayCodes}
                selectedElement={selectedPayCode}
                setSelectedElement={setSelectedPayCode}
                createUpdateUrl={'payroll/pay-code/'}
                elementType={ElementTypes.PAY_CODE}
                exportUrl={'payroll/pay-code/export/'}
              />
            )}
            {selectedTab === 4 && (
              <DepartmentSettingsTab
                elements={capabilityPermissions}
                setElements={setCapabilityPermissions}
                selectedElement={selectedCapabilityPermission}
                setSelectedElement={setSelectedCapabilityPermission}
                createUpdateUrl={'customization/capability-permissions/'}
                elementType={ElementTypes.CAPABILITY_PERMISSION}
              />
            )}
            {selectedTab === 5 && <HiringEngineDepartmentSettingsTab />}
            {selectedTab === 6 && (
              <DepartmentSettingsTab
                elements={timeOffAccruals}
                setElements={setTimeOffAccruals}
                selectedElement={selectedTimeOffAccrual}
                setSelectedElement={setSelectedTimeOffAccrual}
                createUpdateUrl={'payroll/time-off-accruals/'}
                elementType={ElementTypes.TIME_OFF_ACCRUAL}
                hasFilters={false}
              />
            )}
            {selectedTab === 7 &&
              departmentInfo.settings.productFeaturesListEmbedUrl.split(',').map((url, index) => (
                <iframe
                  className="airtable-embed"
                  src={url}
                  key={index}
                  width="100%"
                  height="600"
                  style={{
                    background: 'transparent',
                    border: '1px solid #ccc',
                  }}
                  title="Airtable Embed"
                />
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
