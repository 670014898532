import { Box, Dialog } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, isAfter, startOfDay } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  DialogXButton,
  Drawer,
  DRAWER_SIDE,
  DrawerHeader,
  HireBackList,
  StaffingListHeader,
  RosterContextProvider,
  useRoster,
  SnackbarService,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  useAuthUserCapabilities,
  getDepartmentFeatureFlagValue,
  asDepartmentDateTime,
  getVacantStations,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import {
  ShiftSummary,
  ShiftSummaryEmployeeAssignmentPayload,
  ShiftSummaryEmployeeOffPayload,
  ListFieldsStaffingList,
  RequestVolunteersDetailsView,
  TemporaryNonShiftType,
  TEMPORARY_NON_SHIFT_TITLES,
  RosterStation,
  RosterAdministrationStation,
  EmployeeOffView,
  RosterTemporaryNonShiftStation,
  AutoHireInfoView,
  InstantHireView,
  HiringEngineVacancy,
} from '@stationwise/share-types';
import {
  differenceInUTCMinutes,
  PUSHER_EVENT_TYPES,
  PUSHER_UPDATE_MESSAGE,
  RefreshEventCallback,
} from '@stationwise/share-utils';
import {
  IShiftSummaryHelper,
  checkIsShift,
  checkIsStrikeTeam,
  checkIsAdministration,
  getBoardEmployees,
  getEmployeeSplits,
} from '@stationwise/shift-summary-helper';
import { CreateButton } from '../../../../components/Common/CreateButton';
import { DRAWER_TYPE, DRAWER_WIDTH } from '../constants';
import { DaySchedule } from './DaySchedule';
import { EmployeesOff } from './EmployeesOff';
import { ForceShiftTradeModal } from './ForceShiftTrade/ForceShiftTradeModal';
import { MessageToWorkingEmployees } from './Messaging/MessageToWorkingEmployees';
import { MoveToTemporaryNonShiftModal } from './MoveToTemporaryNonShiftModal';
import { PrintDaySchedulePrint } from './PrintDaySchedule/PrintDaySchedulePrint';
import { RosterChangeLogDrawer } from './RosterChangelog';
import { StaffingStatsDrawer } from './StaffingStats';
import { StrikeTeam } from './StrikeTeam';
import { EditStrikeTeamModal } from './StrikeTeam/EditStrikeTeamModal';
import { TopBar } from './TopBar';
import { UnplannedAssignmentWarning } from './UnplannedAssignmentWarning';

interface ScheduleContentProps {
  selectedBattalionState: [number | undefined, Dispatch<SetStateAction<number | undefined>>];
  shiftSummary: ShiftSummary;
  administrationStations: RosterAdministrationStation[];
  eventStations: RosterStation[];
  temporaryNonShiftStation: RosterTemporaryNonShiftStation;
  currentDate: Date;
  forceRefetch: (battalionId?: number, resetData?: boolean) => void;
  staffingListsResponse: { data: ListFieldsStaffingList[] | null; isError: boolean };
  employeesOff: EmployeeOffView[];
  hiringEngineResponse: {
    isLoading: boolean;
    requestVolunteers: RequestVolunteersDetailsView[];
    autoHireInfo: AutoHireInfoView | null;
    instantHires: InstantHireView[];
    forceRefetchHiringEngine: () => void;
  };
}

export const ScheduleContent = ({
  selectedBattalionState,
  shiftSummary,
  administrationStations,
  eventStations,
  temporaryNonShiftStation,
  currentDate,
  forceRefetch,
  staffingListsResponse,
  employeesOff,
  hiringEngineResponse,
}: ScheduleContentProps) => {
  const roster = useRoster({
    selectedBattalionState,
    shiftSummary,
    administrationStations,
    temporaryNonShiftStation,
    eventStations,
    employeesOff,
    currentDate,
    staffingListsResponse,
    forceRefetch,
  });
  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [openedDrawer, setOpenedDrawer] = useState<DRAWER_TYPE | null>(null);

  const vacantStations = useMemo(() => {
    return getVacantStations(Array.from(roster.shiftSummaryHelper.allStationCards.values()));
  }, [roster.shiftSummaryHelper.allStationCards]);

  const [alertShown, setAlertShown] = useState(false);
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentContext.refreshTriggerChannel;
  const [saveDisabled, setSaveDisabled] = useState(false);

  const capabilities = useAuthUserCapabilities();

  useEffect(() => {
    if (!refreshTriggerChannel || !forceRefetch) return;
    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if ((data.triggerAll || data.message === PUSHER_UPDATE_MESSAGE) && !alertShown) {
        const isStaffingEvent = data.eventType === PUSHER_EVENT_TYPES.STAFFING;
        if (
          isSavingLoading ||
          isAfter(roster.shiftSummaryHelper.createdAt, data.createdAt) ||
          data.eventType === 'INTERVAL' ||
          (isStaffingEvent && (!data.data || data.data.shiftDate !== format(currentDate, 'yyyy-MM-dd'))) ||
          (data.data && data.data.battalionId && data.data.battalionId !== selectedBattalionState[0])
        ) {
          return;
        }

        // If there are no pending changes, refresh immediately
        if (!roster.userHasMadeChanges) {
          forceRefetch(undefined, false);
          return;
        }

        // If there are pending changes, show snackbar
        setSaveDisabled(true); // disable the save button
        SnackbarService.notify({
          severity: 'info',
          content: 'There have been new changes in the schedule. Do you want to refresh the page?',
          actionButtonText: 'Refresh',
          showCloseButton: false,
          onCallToAction: () => {
            forceRefetch();
            SnackbarService.clearQueue();
          },
        });
        setAlertShown(true);
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.SHIFT_PLANNING, PUSHER_EVENT_TYPES.STAFFING];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
      setAlertShown(false);
    };
  }, [
    refreshTriggerChannel,
    forceRefetch,
    alertShown,
    isSavingLoading,
    roster.shiftSummaryHelper.createdAt,
    setSaveDisabled,
    currentDate,
    selectedBattalionState,
    roster.userHasMadeChanges,
  ]);

  const getShiftSummaryAssignmentPayloads = (newShiftSummaryHelper: IShiftSummaryHelper) => {
    const assignments: ShiftSummaryEmployeeAssignmentPayload[] = [];
    const offs: ShiftSummaryEmployeeOffPayload[] = [];
    const employeeSplits = getEmployeeSplits({ ...roster.employeeOffPayloads, shiftSummaryHelper: newShiftSummaryHelper });
    employeeSplits.forEach((splits, employeeId) => {
      if (splits.some((s) => s.employee || (s.reference.type !== 'ASSIGNMENT' && s.reference.id <= 0))) {
        splits.forEach((split) => {
          if (split.employee && checkIsAdministration(split.employee) && split.reference.type !== 'TIME_OFF_REQUEST') {
            return;
          }

          const payload = {
            employeeId,
            startTime: differenceInUTCMinutes(split.startDateTime, newShiftSummaryHelper.shiftDuration.startTime),
            endTime: differenceInUTCMinutes(split.endDateTime, newShiftSummaryHelper.shiftDuration.startTime),
          };
          if (split.reference.type === 'ASSIGNMENT') {
            assignments.push({
              ...payload,
              apparatusId: split.reference.apparatus?.id || null,
              positionId: split.reference.position?.id || null,
              payCodeIds: split.reference.payCodes.map((pc) => pc.id),
              detailCodeIds: split.reference.detailCodes.map((dc) => dc.id),
              staffedAt: split.employee?.staffedAt || null,
            });
          } else if (split.reference.type === 'SHIFT_TRADE_REQUEST') {
            offs.push({
              ...payload,
              offType: split.reference.type,
              offId: split.reference.id,
              isOvertime: split.reference.isOvertime,
              receiverId: Number(split.reference.receiver.id),
              positionId: split.reference.positionId,
            });
          } else if (split.reference.type === 'TIME_OFF_REQUEST') {
            offs.push({
              ...payload,
              offType: split.reference.type,
              offId: split.reference.id,
              payCodeId: split.reference.payCodeId,
              positionId: split.reference.positionId,
            });
          } else {
            offs.push({
              ...payload,
              offType: split.reference.type,
              offId: split.reference.id,
            });
          }
        });
      }
    });

    return { assignments, offs };
  };

  const getShiftEmployeeNotesPayload = (newShiftSummaryHelper: IShiftSummaryHelper) => {
    const employees = getBoardEmployees(newShiftSummaryHelper).filter((e) => {
      return (
        (checkIsShift(e) || checkIsStrikeTeam(e)) &&
        (!e.noteOverride || e.noteOverride.overrideBy.id === authUserState.employee.id)
      );
    });
    return employees.map((e) => ({ employeeId: e.id, note: e.noteOverride?.note || null }));
  };

  useEffect(() => {
    if (
      capabilities.MANAGE_ROSTER_HIRING &&
      roster.selectedEmptyPositionState.position &&
      checkIsShift(roster.selectedEmptyPositionState.position)
    ) {
      setOpenedDrawer(DRAWER_TYPE.HIREBACK);
    }
  }, [roster.selectedEmptyPositionState.position, capabilities.MANAGE_ROSTER_HIRING]);

  const saveChanges = async () => {
    setOpenedDrawer(null);
    let newShiftSummaryHelper = roster.shiftSummaryHelper;
    let step = '';
    try {
      setIsSavingLoading(true);
      step = 'CANCEL_TEMPORARY_NON_SHIFT_ASSIGNMENTS';
      const unassigns = roster.temporaryNonShiftAssignmentsState.cancelledAssignmentIds.map((id) => ({
        temporaryNonShiftAssignmentId: id,
      }));
      if (unassigns.length > 0) {
        await client.post('/non-shift/temporary-lists/', {
          date: format(newShiftSummaryHelper.shiftDuration.startTime, 'yyyy-MM-dd'),
          unassigns,
        });
      }
      step = 'SAVE_STRIKE_TEAMS';
      newShiftSummaryHelper = await roster.saveStrikeTeamsState.save();
      step = 'SAVE_SHIFT_SUMMARY';
      const response = await client.post(`/shift/summary/?shift_date=${format(currentDate, 'MM/dd/yyyy')}`, {
        battalionId: shiftSummary.battalion.id,
        ...getShiftSummaryAssignmentPayloads(newShiftSummaryHelper),
        cancelShiftTrades: newShiftSummaryHelper.cancelShiftTradePayloads,
        cancelTimeOffs: newShiftSummaryHelper.cancelTimeOffPayloads,
        employeeNotes: getShiftEmployeeNotesPayload(newShiftSummaryHelper),
      });
      step = 'SAVE_TEMPORARY_NON_SHIFT_ASSIGNMENTS';
      await roster.temporaryNonShiftAssignmentsState.save();
      forceRefetch();
      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      let message = '';
      if (step === 'SAVE_SHIFT_SUMMARY') {
        message = isAxiosError(error) ? error.response?.data.message : '';
        message = message || 'Error saving changes';
      } else if (step === 'SAVE_STRIKE_TEAMS') {
        message = isAxiosError(error) ? error.response?.data.nonFieldErrors?.[0] : '';
        message = message || 'Error saving events';
      } else if (step === 'SAVE_TEMPORARY_NON_SHIFT_ASSIGNMENTS') {
        message = isAxiosError(error) ? error.response?.data.nonFieldErrors?.[0] : '';
        message = message || `Error saving ${Array.from(TEMPORARY_NON_SHIFT_TITLES.values()).join(', ').toLowerCase()}`;
      } else if (step === 'CANCEL_TEMPORARY_NON_SHIFT_ASSIGNMENTS') {
        message = isAxiosError(error) ? error.response?.data.nonFieldErrors?.[0] : '';
        message = message || `Error cancelling ${Array.from(TEMPORARY_NON_SHIFT_TITLES.values()).join(', ').toLowerCase()}`;
      }
      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
      setIsSavingLoading(false);
    }
  };

  const groupedVacancies = useMemo(() => {
    const now = asDepartmentDateTime(departmentContext, new Date());
    const currentDateStart = startOfDay(currentDate);
    const [shiftStartHour, shiftStartMinute] = departmentContext.departmentInfo.shiftStart.split(':').map(Number);
    const currentDayHiringEngineEnabled = getDepartmentFeatureFlagValue(
      departmentContext,
      'CURRENT_DAY_HIRING_ENGINE_ENABLED',
      false,
    );

    // For past dates, always return empty
    if (
      currentDateStart.getTime() < startOfDay(now).getTime() &&
      now.getHours() * 60 + now.getMinutes() >= shiftStartHour * 60 + shiftStartMinute
    ) {
      return {};
    }

    // For current date, return empty if feature flag is disabled
    if (!currentDayHiringEngineEnabled && !isAfter(currentDateStart, startOfDay(now))) {
      return {};
    }

    const allVacancies = vacantStations
      .flatMap((station) =>
        station.apparatuses.flatMap((apparatus) =>
          apparatus.vacancies.map((vacancy) => ({
            ...vacancy,
            stationName: station.stationName,
            apparatusName: apparatus.name,
          })),
        ),
      )
      .filter((vacancy) => {
        if (vacancy.rank.code === 'CT') {
        }
        return isAfter(vacancy.endDateTime, now);
      });

    const sortedVacancies = allVacancies.sort((a, b) => a.rank.sortOrder - b.rank.sortOrder);

    const grouped = sortedVacancies.reduce(
      (groups, vacancy) => {
        const rankId = vacancy.rank.id;
        if (!groups[rankId]) {
          groups[rankId] = [];
        }
        groups[rankId].push(vacancy);
        return groups;
      },
      {} as Record<number, HiringEngineVacancy[]>,
    );

    return Object.fromEntries(Object.entries(grouped).filter(([_rankId, vacancies]) => vacancies.length > 0));
  }, [vacantStations, currentDate, departmentContext]);
  return (
    <RosterContextProvider roster={roster}>
      <Box>
        <TopBar
          saveChanges={saveChanges}
          isSaving={isSavingLoading}
          openedDrawer={openedDrawer}
          setOpenedDrawer={setOpenedDrawer}
          saveDisabled={saveDisabled}
          groupedVacancies={groupedVacancies}
          forceRefetch={forceRefetch}
          vacantStations={vacantStations}
          hiringEngineResponse={hiringEngineResponse}
          disableForward={!shiftSummary.tomorrowShiftDateExists}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          position: 'relative',
          overflow: 'hidden',
          '& .SWRosterBoard-root': {
            mr: openedDrawer ? `${DRAWER_WIDTH[openedDrawer]}px` : 0,
          },
        }}
      >
        {capabilities.MANAGE_ROSTER_HIRING && (
          <Drawer
            isOpen={openedDrawer === DRAWER_TYPE.HIREBACK}
            onClickOutside={() => setOpenedDrawer(null)}
            side={DRAWER_SIDE.RIGHT}
            drawerSize={`${DRAWER_WIDTH.HIREBACK}px`}
          >
            <Box
              data-cy="overtime-list-drawer"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <DrawerHeader title="Staffing list" onClick={() => setOpenedDrawer(null)} titleComponent={<StaffingListHeader />}>
                <HireBackList />
              </DrawerHeader>
            </Box>
          </Drawer>
        )}
        {capabilities.EDIT_OFF_ROSTER && (
          <Drawer
            isOpen={openedDrawer === DRAWER_TYPE.EMPLOYEES_OFF}
            onClickOutside={() => setOpenedDrawer(null)}
            side={DRAWER_SIDE.RIGHT}
            drawerSize={`${DRAWER_WIDTH.EMPLOYEES_OFF}px`}
          >
            <Box
              data-cy="employees-off-drawer"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'scroll',
              }}
            >
              <DrawerHeader
                title="Off roster"
                titleComponent={<Box sx={(theme) => ({ pl: theme.spacing(2), typography: 'bodyMSemibold' })}>Off Roster</Box>}
                onClick={() => setOpenedDrawer(null)}
              >
                <EmployeesOff />
              </DrawerHeader>
            </Box>
          </Drawer>
        )}
        <Drawer
          isOpen={openedDrawer === DRAWER_TYPE.MESSAGE}
          onClickOutside={() => setOpenedDrawer(null)}
          side={DRAWER_SIDE.RIGHT}
          drawerSize={`${DRAWER_WIDTH.MESSAGE}px`}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <DrawerHeader
              title="Message"
              titleComponent={<Box sx={(theme) => ({ pl: theme.spacing(2), typography: 'bodyMSemibold' })}>Message</Box>}
              onClick={() => setOpenedDrawer(null)}
            >
              <MessageToWorkingEmployees setOpenedDrawer={setOpenedDrawer} isOpen={openedDrawer === DRAWER_TYPE.MESSAGE} />
            </DrawerHeader>
          </Box>
        </Drawer>
        <Drawer
          isOpen={openedDrawer === DRAWER_TYPE.STAFFING_STATS}
          onClickOutside={() => setOpenedDrawer(null)}
          side={DRAWER_SIDE.RIGHT}
          drawerSize={`${DRAWER_WIDTH.STAFFING_STATS}px`}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <DrawerHeader
              title="Staffing stats"
              titleComponent={<Box sx={(theme) => ({ pl: theme.spacing(2), typography: 'bodyMSemibold' })}>Staffing stats</Box>}
              onClick={() => setOpenedDrawer(null)}
            >
              <StaffingStatsDrawer selectedDate={currentDate}></StaffingStatsDrawer>
            </DrawerHeader>
          </Box>
        </Drawer>
        {capabilities.VIEW_CHANGE_LOGS && (
          <Drawer
            isOpen={openedDrawer === DRAWER_TYPE.CHANGELOG}
            onClickOutside={() => setOpenedDrawer(null)}
            side={DRAWER_SIDE.RIGHT}
            drawerSize={`${DRAWER_WIDTH.CHANGELOG}px`}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'scroll',
              }}
            >
              <DrawerHeader
                title="Change Log"
                titleComponent={<Box sx={(theme) => ({ pl: theme.spacing(2), typography: 'bodyMSemibold' })}>Change Log</Box>}
                onClick={() => setOpenedDrawer(null)}
              >
                <RosterChangeLogDrawer selectedDate={currentDate} />
              </DrawerHeader>
            </Box>
          </Drawer>
        )}
        <DaySchedule isLoading={isSavingLoading} />
        <PrintDaySchedulePrint />
        {!roster.isReadonly && capabilities.EDIT_EVENTS && (
          <>
            <CreateButton data-cy="create-event-button" onClick={() => roster.createStrikeTeamModalState.setIsOpen(true)}>
              Create event
            </CreateButton>
            <Dialog
              open={roster.createStrikeTeamModalState.isOpen}
              onClose={() => roster.createStrikeTeamModalState.setIsOpen(false)}
            >
              <DialogXButton onClose={() => roster.createStrikeTeamModalState.setIsOpen(false)} />
              <StrikeTeam />
            </Dialog>
          </>
        )}
        <MoveToTemporaryNonShiftModal temporaryNonShiftType={TemporaryNonShiftType.LIGHT_DUTY} />
        <MoveToTemporaryNonShiftModal temporaryNonShiftType={TemporaryNonShiftType.EXTENDED_LEAVE} />
        <ForceShiftTradeModal />
        <EditStrikeTeamModal />
        <UnplannedAssignmentWarning />
      </Box>
    </RosterContextProvider>
  );
};
