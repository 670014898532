import { Box, Tab, Tabs, useTheme, useMediaQuery, Typography, TabProps } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthUserCapabilities, useLoadedAuthUserContext } from '@stationwise/component-module';
import { EmployeeTypes } from '@stationwise/share-types';
import { ROUTES } from '../../core/Routes';

export const calendarOptions = ['Month', 'List'];
export const calendarPaths = calendarOptions.map((option) => `${ROUTES.CALENDAR.fullRoute}?display=${option.toLowerCase()}`);

const TAB_PATHS = {
  dashboard: ROUTES.MY_DASHBOARD.fullRoute,
  calendar: calendarPaths[0],
  payroll: ROUTES.MY_PAYROLL.fullRoute,
  staffingLists: ROUTES.MY_STAFFING_LISTS.fullRoute,
  preferences: ROUTES.PREFERENCES.fullRoute,
};

const TAB_LABELS = {
  dashboard: 'My Dashboard',
  calendar: 'My Calendar',
  payroll: 'My Payroll',
  staffingLists: 'My Staffing Lists',
  preferences: 'Preferences',
};

interface PersonalTabsProps {
  children: ReactNode;
  defaultTab: 'dashboard' | 'calendar' | 'payroll' | 'staffingLists' | 'preferences';
}

interface CustomTabProps extends TabProps {
  label: string;
  value: number;
}

export const PersonalTabs = ({ children, defaultTab }: PersonalTabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const capabilities = useAuthUserCapabilities();
  const { state: authUserState } = useLoadedAuthUserContext();
  const isVolunteer = authUserState.employee.employeeType === EmployeeTypes.VOLUNTEER;
  const isNonShift = authUserState.employee.isNonShift;
  const canViewMyStaffingLists = capabilities.VIEW_MY_STAFFING_LISTS && !isVolunteer && !isNonShift;

  const getInitialTab = () => {
    const tabMap = {
      [TAB_PATHS.dashboard]: 0,
      [TAB_PATHS.calendar]: 1,
      [TAB_PATHS.payroll]: 2,
      [TAB_PATHS.staffingLists]: canViewMyStaffingLists ? 3 : null,
      [TAB_PATHS.preferences]: canViewMyStaffingLists ? 4 : 3,
    };

    const defaultTabMap = {
      dashboard: 0,
      calendar: 1,
      payroll: 2,
      staffingLists: canViewMyStaffingLists ? 3 : null,
      preferences: canViewMyStaffingLists ? 4 : 3,
    };

    // Check if the current path starts with the calendar route
    if (location.pathname === ROUTES.CALENDAR.fullRoute) {
      return 1; // Calendar tab index
    }

    const mappedTab = tabMap[location.pathname];
    if (mappedTab !== null) {
      return mappedTab;
    }
    return defaultTabMap[defaultTab];
  };

  const [activeTab, setActiveTab] = useState(getInitialTab);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    switch (newValue) {
      case 0:
        navigate(TAB_PATHS.dashboard);
        break;
      case 1:
        navigate(TAB_PATHS.calendar);
        break;
      case 2:
        navigate(TAB_PATHS.payroll);
        break;
      case 3:
        if (canViewMyStaffingLists) {
          navigate(TAB_PATHS.staffingLists);
        } else {
          navigate(TAB_PATHS.preferences);
        }
        break;
      case 4:
        navigate(TAB_PATHS.preferences);
        break;
    }
  };

  const CustomTab = React.forwardRef<HTMLDivElement, CustomTabProps>(function CustomTab(props, ref) {
    const { label, value, ...otherProps } = props;
    return (
      <Tab
        ref={ref}
        {...otherProps}
        sx={{
          textTransform: 'none',
          color: theme.palette.text.primary,
          typography: 'body2',
          alignItems: 'flex-start',
          textAlign: 'left',
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            color: theme.palette.text.primary,
          },
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
        label={label}
        value={value}
      />
    );
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.common.white,
      }}
    >
      {isDesktop && (
        <Box sx={{ p: theme.spacing(2), borderBottom: `1px solid ${theme.palette.divider}` }}>
          <Typography sx={{ color: theme.palette.text.primary, typography: 'h4' }}>You</Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {isDesktop && (
          <Box sx={{ borderRight: 1, borderColor: 'divider', width: '256px' }}>
            <Tabs
              orientation="vertical"
              value={activeTab}
              onChange={handleTabChange}
              aria-label="Personal section tabs"
              sx={{
                '& .MuiTab-root': {
                  alignItems: 'flex-start',
                  textAlign: 'left',
                  px: theme.spacing(2),
                  py: theme.spacing(1.5),
                  textTransform: 'none',
                },
              }}
            >
              <CustomTab label={TAB_LABELS.dashboard} value={0} />
              <CustomTab label={TAB_LABELS.calendar} value={1} />
              <CustomTab label={TAB_LABELS.payroll} value={2} />
              {canViewMyStaffingLists && <CustomTab label={TAB_LABELS.staffingLists} value={3} data-cy="my-staffing-lists-tab" />}
              <CustomTab label={TAB_LABELS.preferences} value={canViewMyStaffingLists ? 4 : 3} />
            </Tabs>
          </Box>
        )}
        <Box sx={{ flex: 1, height: '100%', overflow: 'auto' }}>{children}</Box>
      </Box>
    </Box>
  );
};
